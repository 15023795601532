import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { auth, db } from './firebase';
import { signOut } from 'firebase/auth'; 
import './App.css';
import preSelectedMovies from './movies.json';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import Signin from './Signin';
import Signup from './Signup';

const API_URL = 'https://www.omdbapi.com/?apikey=9e17ff';

const App = () => {
  const [query, setQuery] = useState('');
  const [movies, setMovies] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState('');

  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUsername(docSnap.data().username);
        }
      } else {
        setUser(null);
        setUsername('');
      }
    });
  }, []);

  const searchMovies = async (searchTerm) => {
    const response = await fetch(`${API_URL}&s=${searchTerm}`);
    const data = await response.json();
    if (data.Search) {
      setMovies(data.Search);
    }
  };

  const fetchMovieDetails = async (movieId) => {
    const response = await fetch(`${API_URL}&i=${movieId}`);
    const data = await response.json();
    setSelectedMovie(data);
  };

  useEffect(() => {
    if (query.length > 2) {
      searchMovies(query);
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
  }, [query]);

  const handleSelectMovie = (movie) => {
    fetchMovieDetails(movie.imdbID);
    setDropdownVisible(false);
  };

  const handlePreSelectMovie = (movieId) => {
    fetchMovieDetails(movieId);
  };

  const handleBack = () => {
    setSelectedMovie(null);
  };

  const handleLogout = () => {
    signOut(auth);
  };

  return (
    <Router>
      <div className="app">
        {/* Navbar */}
        <nav className="navbar">
          <Link to="/" className="home-link" onClick={handleBack}>
            <div className="logo">MBH</div>
          </Link>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search for a movie..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onFocus={() => setDropdownVisible(true)}
            />
            {dropdownVisible && (
              <div className="dropdown">
                {movies.slice(0, 5).map((movie) => (
                  <div key={movie.imdbID} className="dropdown-item" onClick={() => handleSelectMovie(movie)}>
                    <img src={movie.Poster} alt={movie.Title} />
                    <div>
                      <h4>{movie.Title}</h4>
                      <p>{movie.Year}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {user ? (
            <div>
              <span>Logged in as <strong>{username}</strong></span>
              <button onClick={handleLogout} className="auth-button">Logout</button>
            </div>
          ) : (
            <div>
   
              <Link to="/signin" className="auth-button">Login</Link>
              <Link to="/signup" className="auth-button">Sign Up</Link>
            </div>
          )}
        </nav>

        {/* Main Content */}
        <Routes>
          <Route
            path="/"
            element={
              !selectedMovie ? (
                <div className="sections-container">
                  {Object.keys(preSelectedMovies).map((genre) => (
                    <div key={genre} className="section">
                      <h2>{genre.charAt(0).toUpperCase() + genre.slice(1)}</h2>
                      <div className="movies-row">
                        {preSelectedMovies[genre].map((movie) => (
                          <div key={movie.imdbID} className="movie-card" onClick={() => handlePreSelectMovie(movie.imdbID)}>
                            <img src={movie.Poster} alt={movie.Title} />
                            <h4>{movie.Title}</h4>
                          </div>
                        ))}
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              ) : user ? (
                <div className="player-page">
                  <div className="content-section">
                    <div className="movie-player">
                    <iframe
  src={`https://multiembed.mov/?video_id=${selectedMovie.imdbID}`}
  width="100%"
  height="500"
  frameBorder="0"
  allowFullScreen
  title={selectedMovie.Title}
></iframe>

                      <h2 className="movie-title">{selectedMovie.Title}</h2>
                      <div className="movie-description">
                        <p><strong>Year:</strong> {selectedMovie.Year}</p>
                        <p><strong>Genre:</strong> {selectedMovie.Genre}</p>
                        <p><strong>Plot:</strong> {selectedMovie.Plot}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="login-prompt">
                  <h2>Please log in to view this movie</h2>
                  <br />
                  <Link to="/signin" className="auth-button">Login</Link>
                </div>
              )
            }
          />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
